import { Override } from "framer"
import { useEffect } from "react"

export function AutoCloseMobileNav(): Override {
    useEffect(() => {
        // Select the component (MobileNav) and get the current variant state
        const mobileNav = document.querySelector(".MobileNav")

        if (mobileNav) {
            const currentState = mobileNav.getAttribute("data-state") // Get the current variant state

            // Only add event listeners if the current state is 'Mobile [Menu Opened]'
            if (currentState === "Mobile [Menu Opened]") {
                const links = mobileNav.querySelectorAll(".mobile-nav-link")

                links.forEach((link) => {
                    link.addEventListener("click", () => {
                        // Change the variant to 'Mobile [Menu Closed]' when a link is clicked
                        mobileNav.setAttribute(
                            "data-state",
                            "Mobile [Menu Closed]"
                        )
                    })
                })
            }
        }
    }, []) // Empty dependency ensures the effect runs only once when mounted

    return {} // Return an empty override
}
